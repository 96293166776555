import Vue from "vue";
import VueRouter from "vue-router";
import VueI18n from "vue-i18n";
import FixedHeader from "vue-fixed-header";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Page from "../../pages/singlepage/page";
import text from "../../assets/scripts/locales";

Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

const routes = [
  {
    path: "/",
    component: Page,
  },
  {
    path: "/about",
    component: Page,
    redirect: "/",
  },
  {
    path: "/sectors",
    component: Page,
    redirect: "/",
  },
  {
    path: "/contacts",
    component: Page,
    redirect: "/",
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  linkActiveClass: "active",
});

const i18n = new VueI18n({
  locale: "it",
  fallbackLocale: "it",
  messages: text,
});

const app = new Vue({
  el: "#app",
  components: {
    Page,
    FixedHeader,
  },
  data: {
    copyright: `@ 2009 - ${new Date().getFullYear()} All rights reserved`,
  },
  router,
  i18n,
});

window.addEventListener("load", function() {
  //Activate the service worker
  // if ('serviceWorker' in navigator) {
  //     let swPath = `service-worker.js`;
  //     navigator.serviceWorker.register(swPath).then(function (registration) {
  //         // Registration was successful
  //         // console.log('ServiceWorker registration successful with scope: ', registration.scope);
  //     }, function (err) {
  //         // registration failed :(
  //         console.log('ServiceWorker registration failed: ', err);
  //     });
  // }
  // Show cookies message
  // window.cookieconsent.initialise({
  //     "palette": {
  //         "popup": {
  //             "background": "#000000"
  //         },
  //         "button": {
  //             "background": "#1091a5"
  //         }
  //     },
  //     "position": "top",
  //     "static": true,
  //     "theme": "classic",
  //     "content": {
  //         "href": "https://www.cookiepolicygenerator.com/live.php?token=cZLxgLRUWrKthuQ0C1LBd81vWUWtm1NF"
  //     }
  // })
});
