 <template>
	<div class="tabs-container">
		<div class="tabs-list">
			<ul :class="{'has-icon': icons == 'true'}" v-if="icons == 'true'">
				<li :class="{ 'is-active': tab.isActive }" v-for="tab in tabs">
					<a :href="tab.href" @click="selectTab(tab)">
						<img :src="getIcon(tab.icon)" />
					</a>
				</li>
			</ul>
			<ul v-else>
				<li :class="{ 'is-active': tab.isActive }" v-for="tab in tabs">
					<a :href="tab.href" @click="selectTab(tab)">{{$i18n.t(tab.name)}}</a>
				</li>
			</ul>
		</div>

		<div class="tabs-details">
			<slot></slot>
		</div>
	</div>
</template>

<script>
import uv from '../../assets/images/uv.svg'
import revamping from '../../assets/images/revamping.svg'
import painting from '../../assets/images/painting.svg'
import controlPanels from '../../assets/images/control_panels.svg'
import automation from '../../assets/images/automation.svg'

const iconList = {
	uv,
	revamping,
	painting,
	controlPanels,
	automation,
}

export default {
	props: ['useIcons'],
	data() {
		return {
			tabs: [],
			icons: false,
		}
	},
	created() {
		this.tabs = this.$children
		this.icons = this.useIcons
	},
	methods: {
		selectTab(selectedTab) {
			this.tabs.forEach(tab => {
				tab.isActive = tab.name == selectedTab.name
			})
		},
		getIcon(icon) {
			return iconList[icon]
		},
	},
}
</script>

<style>
@import './tabs.scss';
</style>