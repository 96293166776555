 <template>
	<div v-show="isActive">
		<slot></slot>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isActive: false,
		}
	},
	props: {
		name: { required: true },
		icon: { default: '' },
		selected: { default: false },
	},
	computed: {
		href() {
			return '#' + this.name.toLowerCase().replace(/ /g, '-')
		},
	},

	mounted() {
		this.isActive = this.selected
	},
}
</script>

<style>
@import './tab.scss';
</style>