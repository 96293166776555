 <template>
	<div>
		<div class="page" id="about">
			<h1 class="title">{{$t('nav.about')}}</h1>
			<div class="about-container">
				<img alt="10 years anniversary" src="../../assets/images/10years.jpg" title="10 years anniversary" />
				<p>{{$t('about.history')}}</p>
			</div>
			<div class="about-container-footer">
				<div class="about-assistance">
					<h2>{{$t('about.assistance.title')}}</h2>
					<p>{{$t('about.assistance.description')}}</p>
					<a href="mailto:info@elettrotecnology.com" class="button primary">{{$t('about.assistance.request')}}</button>
				</div>
				<div class="opening-times">
					<h2>{{$t('about.timetable.title')}}</h2>
					<table class="opening-times-table">
						<tr>
							<td class="day">{{$t('about.timetable.mon')}}</td>
							<td class="time">8:30 - 13:30 | 14:30 - 18:30</td>
						</tr>
						<tr>
							<td class="day">{{$t('about.timetable.tue')}}</td>
							<td class="time">8:30 - 13:30 | 14:30 - 18:30</td>
						</tr>
						<tr>
							<td class="day">{{$t('about.timetable.wed')}}</td>
							<td class="time">8:30 - 13:30 | 14:30 - 18:30</td>
						</tr>
						<tr>
							<td class="day">{{$t('about.timetable.thu')}}</td>
							<td class="time">8:30 - 13:30 | 14:30 - 18:30</td>
						</tr>
						<tr>
							<td class="day">{{$t('about.timetable.fri')}}</td>
							<td class="time">8:30 - 13:30 | 14:30 - 18:30</td>
						</tr>
						<tr>
							<td class="day">{{$t('about.timetable.sat')}}</td>
							<td class="time">{{$t('about.timetable.closed')}}</td>
						</tr>
						<tr>
							<td class="day">{{$t('about.timetable.sun')}}</td>
							<td class="time">{{$t('about.timetable.closed')}}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
		<div class="page" id="sectors">
			<h1 class="title">{{$t('nav.sectors')}}</h1>

			<div class="sectors-container">
				<tabs useIcons="true">
					<!--
					<tab :selected="true" icon="revamping" name="sectors.tabs.revamping">
						<h2>{{$t('sectors.tabs.revamping')}}</h2>
						<p>---</p>
					</tab>
					-->
					<tab :selected="true" icon="uv" name="sectors.tabs.uvsystems">
						<h2>{{$t('sectors.tabs.uvsystems')}}</h2>
						<p class="two-columns">
							<span class="left" v-html="$t('sectors.tab_uv_systems.main')"></span>
							<!-- <h3>{{$t('sectors.tab_uv_systems.dryers.title')}}</h3>
							<span v-html="$t('sectors.tab_uv_systems.dryers.main')"></span>-->
							<!-- <hr>
							<div>
								<strong v-html="$t('sectors.tab_uv_systems.dryers.series.PLE.title')"></strong>
								<p v-html="$t('sectors.tab_uv_systems.dryers.series.PLE.description')"></p>
							</div>
							<div>
								<strong v-html="$t('sectors.tab_uv_systems.dryers.series.ES.title')"></strong>
								<p v-html="$t('sectors.tab_uv_systems.dryers.series.ES.description')"></p>
							</div>
							<p v-html="$t('sectors.tab_uv_systems.dryers.footer')"></p>-->
						</p>
						<b-card class="mb-1" no-body>
							<b-card-header class="p-1" header-tag="header" role="tab" visible>
								<b-button block v-b-toggle.accordion-1 variant="default">Essiccatori</b-button>
							</b-card-header>
							<b-collapse accordion="my-accordion" id="accordion-1" role="tabpanel" visible>
								<b-card-body class="description">
									<b-card-text class="two-columns">
										<span class="left">Gli essiccatori di nostra produzione si differenziano in due sottocategorie, PLE ed ES e ambedue sono
										costruiti con una struttura altamente resistente in alluminio, e una delle loro caratteristiche principali è
										l’utilizzo di riflettori ellittici con speculare intercambiabile in grado di migliorare e concentrare la resa della
										lampada stessa.
										<a
											href="../../assets/docs/ESSICCATORI.pdf"
											target="_blank"
										>Scarica dettagli come pdf</a>
										</span>
										<span class="right">
											<img alt="uv hoven 1" class="medium" src="../../assets/images/sectors/essiccatori/essiccatori.jpg" />
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>

						<b-card class="mb-1" no-body>
							<b-card-header class="p-1" header-tag="header" role="tab">
								<b-button block v-b-toggle.accordion-2 variant="default">Quadri di comando e alimentazione</b-button>
							</b-card-header>
							<b-collapse accordion="my-accordion" id="accordion-2" role="tabpanel">
								<b-card-body>
									<b-card-text>
										I quadri di comando e alimentazione di nostra produzione si differenziano in due sottocategorie, QCT e
										QCTE, in entrambi i casi, tutti i materiali di costruzione, dai componenti alle carpenterie utilizzati sono stati
										attentamente scelti per fornire il migliore prodotto possibile e tecnologicamente avanzato.
										I componenti elettromeccanici utilizzati sono certificati CE
										Inoltre, entrambe le serie possono essere installate e certificate INDUSTRIA 4.0 e con sistemi di
										teleassistenza e telecontrollo a distanza.
										Ogni nostro quadro sia della serie QCT e sia della serie QCTE si possono predisporre con ogni comando o
										controllo esterno ad esso ciò permette una totale dinamicità nel controllo, quindi possono essere installati
										o sostituiti su linee di produzione già esistenti, inoltre essendo gestiti interamente da PLC e da interfaccia
										uomo/macchina di nuova generazione HMI Touch Screen siamo in grado di interagire e visualizzare
										direttamente tutte le informazioni e i comandi per una corretta gestione di ogni dispositivo.
										<a
											href="../../assets/docs/QUADRI_DI_COMANDO_ED_ALIMENTAZIONE.pdf"
											target="_blank"
										>Scarica dettagli come pdf</a>
										<img alt="uv hoven 1" class="medium" src="../../assets/images/sectors/uv_systems/quadro_uv.png" />
										<img alt="uv hoven 1" class="medium" src="../../assets/images/sectors/uv_systems/quadro_uv_2.jpg" />
										<img alt="uv hoven 1" class="medium" src="../../assets/images/sectors/uv_systems/quadro_uv_4.jpeg" />
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>

						<b-card class="mb-1" no-body>
							<b-card-header class="p-1" header-tag="header" role="tab">
								<b-button block v-b-toggle.accordion-3 variant="default">Rotary e Statory</b-button>
							</b-card-header>
							<b-collapse accordion="my-accordion" id="accordion-3" role="tabpanel">
								<b-card-body class="description">
									<b-card-text class="two-columns">
										<span class="left">
										Sia gli Statory che i Rotary sono sistemi per la reticolazione o polimerizzazione tramite i raggi UV pronti
										all’uso, una volta collegati possono essere integrati o inseriti in linee di produzione già esistenti a seconda
										delle necessità e senza bisogno di eseguire modifiche strutturatili o altamente dispendiose.
										<a
											href="../../assets/docs/ROTARY_E_STATORY.pdf"
											target="_blank"
										>Scarica dettagli come pdf</a>
										</span>
										<span class="right">
											<img alt="uv hoven 1" class="medium" src="../../assets/images/sectors/rotary/rotary.jpg" /><img alt="uv hoven 1" class="medium" src="../../assets/images/sectors/rotary/rotary_1.png" />
										</span>
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>

						<b-card class="mb-1" no-body>
							<b-card-header class="p-1" header-tag="header" role="tab">
								<b-button block v-b-toggle.accordion-4 variant="default">Forni statici a raggi UV</b-button>
							</b-card-header>
							<b-collapse accordion="my-accordion" id="accordion-4" role="tabpanel">
								<b-card-body class="details">
									<b-card-text class="two-columns">
										<span class="left">
										Sono sistemi o forni indipendenti studiati per la polimerizzazione o reticolazione tramite i raggi
										UV in forma statica, sono costruiti in una struttura unica che contiene sia la parte di comando e
										alimentazione che la parte di trattamento e possono alloggiare da 1 a più unità di essiccazione sia in
										posizione longitudinale che perpendicolare e sono provvisti di un vano di trattamento ove è alloggiato un
										cassetto scorrevole, sono particolarmente indicati per piccole produzioni.
										<a
											href="../../assets/docs/FORNI_STATICI_A_RAGGI_UV.pdf"
											target="_blank"
										>Scarica dettagli come pdf</a>
										</span>
										<span class="right">
											<img alt="uv hoven 1" class="medium" src="../../assets/images/sectors/uv_systems/uv_system_1.jpg" />
											<img alt="uv hoven 2" class="medium" src="../../assets/images/sectors/uv_systems/uv_system_2.jpg" />
										</span>
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>

						<b-card class="mb-1" no-body>
							<b-card-header class="p-1" header-tag="header" role="tab">
								<b-button block v-b-toggle.accordion-5 variant="default">Alimentatori elettromeccanici</b-button>
							</b-card-header>
							<b-collapse accordion="my-accordion" id="accordion-5" role="tabpanel">
								<b-card-body>
									<b-card-text>
										Questi alimentatori sono stati studiati appositamente per l’alimentazione e il controllo delle lampade
										a raggi UV, prestando particolare attenzione alla loro completa autonomia in fase di accensione e alla
										regolazione.
										La loro alta impedenza interna è in grado di sostenere autonomamente il “cortocircuito” tipico delle
										accensioni delle lampade UV senza creare alte correnti distruttive.
										Inoltre, per far sì che le fasi di accensione sia il mono dannosa possibile viene inserita in serie una
										impedenza di limitazione gestita tramite un sistema temporizzato.
										Questi alimentatori sono suddivisi in tre sottocategorie, AUV-F, AUV-P e AUV-V.
										<a
											href="../../assets/docs/ALIMENTATORI_ELETTROMECCANICI.pdf"
											target="_blank"
										>Scarica dettagli come pdf</a>
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>

						<b-card class="mb-1" no-body>
							<b-card-header class="p-1" header-tag="header" role="tab">
								<b-button block v-b-toggle.accordion-6 variant="default">Alimentatori elettronici ad inverter</b-button>
							</b-card-header>
							<b-collapse accordion="my-accordion" id="accordion-6" role="tabpanel">
								<b-card-body>
									<b-card-text>
										Questo alimentatore di nuova concezione è l’evoluzione dei sistemi più classici, le sue proprietà di
										risparmio energetico, migliore efficienza e maggiore rispetto della lampada stessa e quindi una maggior
										durata e un maggiore rendimento fanno di questo prodotto un salto di qualità notevole per le aziende che
										lo utilizzano.
										Una delle sue caratteristiche principali è proprio la completa intercambiabilità con i sistemi più tradizionali,
										con delle piccole modifiche al sistema tradizionale siamo in grado di poter sostituire il sistema tradizionale
										con il sistema elettronico.
										<a
											href="../../assets/docs/ALIMENTATORI_ELETTRONICI_AD_INVERTER.pdf"
											target="_blank"
										>Scarica dettagli come pdf</a>
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>

						<b-card class="mb-1" no-body>
							<b-card-header class="p-1" header-tag="header" role="tab">
								<b-button block v-b-toggle.accordion-7 variant="default">Macchine e Impianti speciali</b-button>
							</b-card-header>
							<b-collapse accordion="my-accordion" id="accordion-7" role="tabpanel">
								<b-card-body>
									<b-card-text>
										Sezione in costruzione
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>
					</tab>
					<tab icon="automation" name="sectors.tabs.automation">
						<h2>{{$t('sectors.tabs.automation')}}</h2>
						<div>
							<span v-html="$t('sectors.tab_automation.main')"></span>
						</div>
						<hr />
						<hr />
						<div class="gallery">
							<img alt="automation system 2" class="small" src="../../assets/images/sectors/automation/automation_1.jpg" />
							<img alt="automation system 2" class="small" src="../../assets/images/sectors/automation/automation_2.jpg" />
							<img alt="automation system 3" class="small" src="../../assets/images/sectors/automation/automation_3.jpg" />
							<img alt="automation system 4" class="small" src="../../assets/images/sectors/automation/automation_4.jpg" />
							<img alt="automation system 5" class="small" src="../../assets/images/sectors/automation/automation.jpg" />
						</div>
						<!-- <hr>
						<div>
							<strong v-html="$t('sectors.tab_automation.development.main')"></strong>
							<ul>
								<li>{{$t('sectors.tab_automation.development.list.one')}}</li>
								<li>{{$t('sectors.tab_automation.development.list.two')}}</li>
								<li>{{$t('sectors.tab_automation.development.list.three')}}</li>
								<li>{{$t('sectors.tab_automation.development.list.four')}}</li>
								<li>{{$t('sectors.tab_automation.development.list.five')}}</li>
								<li>{{$t('sectors.tab_automation.development.list.six')}}</li>
								<li>{{$t('sectors.tab_automation.development.list.seven')}}</li>
								<li>{{$t('sectors.tab_automation.development.list.eight')}}</li>
						</div>
						
						<p v-html="$t('sectors.tab_automation.footer')"></p>-->
					</tab>
					<tab icon="painting" name="sectors.tabs.paintingSystems">
						<h2>{{$t('sectors.tabs.paintingSystems')}}</h2>
						<div class="two-columns">
							<span class="left">
								<img alt="painting systems 1" class="small" src="../../assets/images/sectors/painting/painting_1.jpg" />
							</span>
							<span class="right" v-html="$t('sectors.tab_painting_systems.main')"></span>
						</div>
						<!-- <hr>
						<div>
							<span v-html="$t('sectors.tab_painting_systems.disadvantages.title')"></span>
							<ul>
								<li>{{$t('sectors.tab_painting_systems.disadvantages.list.one')}}</li>
								<li>{{$t('sectors.tab_painting_systems.disadvantages.list.two')}}</li>
								<li>{{$t('sectors.tab_painting_systems.disadvantages.list.three')}}</li>			
							</ul>
						</div>
						<div v-html="$t('sectors.tab_painting_systems.main2')"></div>
						<div>
							<ul>
								<li>{{$t('sectors.tab_painting_systems.list2.one')}}</li>
								<li>{{$t('sectors.tab_painting_systems.list2.two')}}</li>
								<li>{{$t('sectors.tab_painting_systems.list2.three')}}</li>			
								<li>{{$t('sectors.tab_painting_systems.list2.four')}}</li>			
								<li>{{$t('sectors.tab_painting_systems.list2.five')}}</li>			
							</ul>
						</div>
						<div v-html="$t('sectors.tab_painting_systems.main3')"></div>
						<div>
							<ul>
								<li>{{$t('sectors.tab_painting_systems.list3.one')}}</li>
								<li>{{$t('sectors.tab_painting_systems.list3.two')}}</li>
								<li>{{$t('sectors.tab_painting_systems.list3.three')}}</li>			
								<li>{{$t('sectors.tab_painting_systems.list3.four')}}</li>			
								<li>{{$t('sectors.tab_painting_systems.list3.five')}}</li>			
							</ul>
						</div>-->
					</tab>
					<!-- 
						<tab icon="controlPanels" name="sectors.tabs.controlPanels">
							<h2>{{$t('sectors.tabs.controlPanels')}}</h2>
							<p>---</p>
						</tab>
					-->
				</tabs>
			</div>
		</div>
		<div class="page" id="contacts">
			<h1 class="title">{{$t('nav.contacts')}}</h1>
			<div class="contacts-container">
				<div class="contacts-container-form">
					<h2>{{$t('contacts.form.title')}}</h2>
					<div>{{$t('contacts.form.description')}}</div>
					<hr />
					<!-- <form id="contact-form" name="contact-form">
						<small>{{$t('contacts.form.mandatory')}}</small>
						<hr />
						<label for="name">
							{{$t('contacts.form.name')}}
							<input
								:placeholder="$t('contacts.form.name')"
								id="name"
								name="name"
								required
								type="text"
							/>
						</label>
						<label for="surname">
							{{$t('contacts.form.surname')}}
							<input
								:placeholder="$t('contacts.form.surname')"
								id="surname"
								name="surname"
								required
								type="text"
							/>
						</label>
						<label for="email">
							{{$t('contacts.form.email')}}
							<input
								:placeholder="$t('contacts.form.email')"
								id="email"
								name="email"
								required
								type="email"
							/>
						</label>
						<label for="message">
							{{$t('contacts.form.message')}}
							<textarea
								:placeholder="$t('contacts.form.message')"
								id="message"
								name="message"
								required
							></textarea>
						</label>
						<hr />
						<input :value="$t('contacts.form.submit')" class="button primary" type="submit" />
					</form>-->
					<a href="mailto:info@elettrotecnology.com" class="button primary">{{$t('about.assistance.request')}}</button>
				</div>
				<div class="contacts-container-details">
					<h2>{{$t('contacts.details.title')}}</h2>
					<ul>
						<li>
							<span class="detail-label">{{$t('contacts.details.tel')}}</span>
							<span class="detail-value">+ 39 0542 643671</span>
						</li>
						<li>
							<span class="detail-label">{{$t('contacts.details.headOffice')}}</span>
							<span class="detail-value">Via F.Turati,13 - 40026 Imola (BO)</span>
						</li>
						<li>
							<span class="detail-label">{{$t('contacts.details.commercialEmail')}}</span>
							<span class="detail-value">info@electrotecnology.com</span>
						</li>
						<li>
							<span class="detail-value">commerciale@electrotecnology.com</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Tabs from '../../components/tabs/tabs'
import Tab from '../../components/tab/tab'

export default {
	data() {
		return {
			locale: 'it',
		}
	},
	components: {
		Tabs,
		Tab,
	},
}
</script>

<style>
@import './page.scss';
</style>
